// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: device.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  Duration,
  Filter_DeviceTypes,
  Filter_HealthLevels,
  Filter_ModemIdentifiers,
  Filter_Modems,
  Filter_Properties,
  Filter_SensorBrands,
  Location,
  TimeRange,
} from "./base";
import { File } from "./file";
import { Struct } from "./google/protobuf/struct";
import { HealthLevel } from "./health";
import { Modem_Lifecycle, modem_LifecycleFromJSON, modem_LifecycleToNumber } from "./modem";
import { Tag, TagSelection } from "./tag";
import {
  Value_Numeric_Rate,
  Value_Numeric_Type,
  value_Numeric_TypeFromJSON,
  value_Numeric_TypeToNumber,
} from "./value";

export const protobufPackage = "hiber.device";

/** Sorting options for the results. */
export enum Sort {
  /**
   * DEVICE_NAME_ASC - Sort alphabetically on the name of the device.
   * De default name of the device is its HEX number, in ascending order.
   */
  DEVICE_NAME_ASC = "DEVICE_NAME_ASC",
  /**
   * DEVICE_NAME_DESC - Sort alphabetically on the name of the device.
   * De default name of the device is its HEX number, in descending order.
   */
  DEVICE_NAME_DESC = "DEVICE_NAME_DESC",
  /** DEVICE_NUMBER_ASC - Sort numerically on the number of the device, in ascending order. */
  DEVICE_NUMBER_ASC = "DEVICE_NUMBER_ASC",
  /** DEVICE_NUMBER_DESC - Sort numerically on the number of the device, in descending order. */
  DEVICE_NUMBER_DESC = "DEVICE_NUMBER_DESC",
  /** ACTIVITY - Sort devices on most recent activity first (e.g. newest message received). */
  ACTIVITY = "ACTIVITY",
  /** INACTIVITY - Sort devices on least recent activity first (e.g. longest no message received). */
  INACTIVITY = "INACTIVITY",
  /** LIFECYCLE_ASC - Sort device on its lifecycle state. */
  LIFECYCLE_ASC = "LIFECYCLE_ASC",
  /** LIFECYCLE_DESC - Sort device on its lifecycle state in reverse order. */
  LIFECYCLE_DESC = "LIFECYCLE_DESC",
  /** LIFECYCLE_ALPHABETICAL_ASC - Sort device on lifecycle name, alphabetically. */
  LIFECYCLE_ALPHABETICAL_ASC = "LIFECYCLE_ALPHABETICAL_ASC",
  /** LIFECYCLE_ALPHABETICAL_DESC - Sort device on lifecycle name, alphabetically in reverse order. */
  LIFECYCLE_ALPHABETICAL_DESC = "LIFECYCLE_ALPHABETICAL_DESC",
  /** ORGANIZATION_ASC - Sort alphabetically on the name of the organization that owns the device, in ascending order. */
  ORGANIZATION_ASC = "ORGANIZATION_ASC",
  /** ORGANIZATION_DESC - Sort alphabetically on the name of the organization that owns the device, in descending order. */
  ORGANIZATION_DESC = "ORGANIZATION_DESC",
  /** HEALTH_ASC - Health sorted from least to most severe (e.g. OK, WARNING, ERROR). */
  HEALTH_ASC = "HEALTH_ASC",
  /** HEALTH_DESC - Health sorted from most to least severe (e.g. ERROR, WARNING, OK). */
  HEALTH_DESC = "HEALTH_DESC",
  /** HEALTH_ALPHABETICAL_ASC - Health sorted alphabetically by health level name. */
  HEALTH_ALPHABETICAL_ASC = "HEALTH_ALPHABETICAL_ASC",
  /** HEALTH_ALPHABETICAL_DESC - Health sorted alphabetically by health level name, descending order. */
  HEALTH_ALPHABETICAL_DESC = "HEALTH_ALPHABETICAL_DESC",
  /** SENSOR_BRAND_ASC - Sort alphabetically on the brand of the sensor, in ascending order. */
  SENSOR_BRAND_ASC = "SENSOR_BRAND_ASC",
  /** SENSOR_BRAND_DESC - Sort alphabetically on the brand of the sensor, in descending order. */
  SENSOR_BRAND_DESC = "SENSOR_BRAND_DESC",
  /** DEVICE_TYPE_ASC - Sort alphabetically on the device type, in ascending order. */
  DEVICE_TYPE_ASC = "DEVICE_TYPE_ASC",
  /** DEVICE_TYPE_DESC - Sort alphabetically on the device type, in descending order. */
  DEVICE_TYPE_DESC = "DEVICE_TYPE_DESC",
  /** TAG_TYPE_WELL_ASC - Sort alphabetically on any tags of type 'well', in ascending order. */
  TAG_TYPE_WELL_ASC = "TAG_TYPE_WELL_ASC",
  /** TAG_TYPE_WELL_DESC - Sort alphabetically on any tags of type 'well', in descending order. */
  TAG_TYPE_WELL_DESC = "TAG_TYPE_WELL_DESC",
  /** TAG_TYPE_SITE_ASC - Sort alphabetically on any tags of type 'site', in ascending order. */
  TAG_TYPE_SITE_ASC = "TAG_TYPE_SITE_ASC",
  /** TAG_TYPE_SITE_DESC - Sort alphabetically on any tags of type 'site', in descending order. */
  TAG_TYPE_SITE_DESC = "TAG_TYPE_SITE_DESC",
  /** TAG_TYPE_PRODUCTION_AREA_ASC - Sort alphabetically on any tags of type 'production_area', in ascending order. */
  TAG_TYPE_PRODUCTION_AREA_ASC = "TAG_TYPE_PRODUCTION_AREA_ASC",
  /** TAG_TYPE_PRODUCTION_AREA_DESC - Sort alphabetically on any tags of type 'production_area', in descending order. */
  TAG_TYPE_PRODUCTION_AREA_DESC = "TAG_TYPE_PRODUCTION_AREA_DESC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function sortFromJSON(object: any): Sort {
  switch (object) {
    case 0:
    case "DEVICE_NAME_ASC":
      return Sort.DEVICE_NAME_ASC;
    case 1:
    case "DEVICE_NAME_DESC":
      return Sort.DEVICE_NAME_DESC;
    case 2:
    case "DEVICE_NUMBER_ASC":
      return Sort.DEVICE_NUMBER_ASC;
    case 3:
    case "DEVICE_NUMBER_DESC":
      return Sort.DEVICE_NUMBER_DESC;
    case 4:
    case "ACTIVITY":
      return Sort.ACTIVITY;
    case 5:
    case "INACTIVITY":
      return Sort.INACTIVITY;
    case 6:
    case "LIFECYCLE_ASC":
      return Sort.LIFECYCLE_ASC;
    case 7:
    case "LIFECYCLE_DESC":
      return Sort.LIFECYCLE_DESC;
    case 8:
    case "LIFECYCLE_ALPHABETICAL_ASC":
      return Sort.LIFECYCLE_ALPHABETICAL_ASC;
    case 9:
    case "LIFECYCLE_ALPHABETICAL_DESC":
      return Sort.LIFECYCLE_ALPHABETICAL_DESC;
    case 10:
    case "ORGANIZATION_ASC":
      return Sort.ORGANIZATION_ASC;
    case 11:
    case "ORGANIZATION_DESC":
      return Sort.ORGANIZATION_DESC;
    case 12:
    case "HEALTH_ASC":
      return Sort.HEALTH_ASC;
    case 13:
    case "HEALTH_DESC":
      return Sort.HEALTH_DESC;
    case 14:
    case "HEALTH_ALPHABETICAL_ASC":
      return Sort.HEALTH_ALPHABETICAL_ASC;
    case 15:
    case "HEALTH_ALPHABETICAL_DESC":
      return Sort.HEALTH_ALPHABETICAL_DESC;
    case 16:
    case "SENSOR_BRAND_ASC":
      return Sort.SENSOR_BRAND_ASC;
    case 17:
    case "SENSOR_BRAND_DESC":
      return Sort.SENSOR_BRAND_DESC;
    case 18:
    case "DEVICE_TYPE_ASC":
      return Sort.DEVICE_TYPE_ASC;
    case 19:
    case "DEVICE_TYPE_DESC":
      return Sort.DEVICE_TYPE_DESC;
    case 20:
    case "TAG_TYPE_WELL_ASC":
      return Sort.TAG_TYPE_WELL_ASC;
    case 21:
    case "TAG_TYPE_WELL_DESC":
      return Sort.TAG_TYPE_WELL_DESC;
    case 22:
    case "TAG_TYPE_SITE_ASC":
      return Sort.TAG_TYPE_SITE_ASC;
    case 23:
    case "TAG_TYPE_SITE_DESC":
      return Sort.TAG_TYPE_SITE_DESC;
    case 24:
    case "TAG_TYPE_PRODUCTION_AREA_ASC":
      return Sort.TAG_TYPE_PRODUCTION_AREA_ASC;
    case 25:
    case "TAG_TYPE_PRODUCTION_AREA_DESC":
      return Sort.TAG_TYPE_PRODUCTION_AREA_DESC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Sort.UNRECOGNIZED;
  }
}

export function sortToNumber(object: Sort): number {
  switch (object) {
    case Sort.DEVICE_NAME_ASC:
      return 0;
    case Sort.DEVICE_NAME_DESC:
      return 1;
    case Sort.DEVICE_NUMBER_ASC:
      return 2;
    case Sort.DEVICE_NUMBER_DESC:
      return 3;
    case Sort.ACTIVITY:
      return 4;
    case Sort.INACTIVITY:
      return 5;
    case Sort.LIFECYCLE_ASC:
      return 6;
    case Sort.LIFECYCLE_DESC:
      return 7;
    case Sort.LIFECYCLE_ALPHABETICAL_ASC:
      return 8;
    case Sort.LIFECYCLE_ALPHABETICAL_DESC:
      return 9;
    case Sort.ORGANIZATION_ASC:
      return 10;
    case Sort.ORGANIZATION_DESC:
      return 11;
    case Sort.HEALTH_ASC:
      return 12;
    case Sort.HEALTH_DESC:
      return 13;
    case Sort.HEALTH_ALPHABETICAL_ASC:
      return 14;
    case Sort.HEALTH_ALPHABETICAL_DESC:
      return 15;
    case Sort.SENSOR_BRAND_ASC:
      return 16;
    case Sort.SENSOR_BRAND_DESC:
      return 17;
    case Sort.DEVICE_TYPE_ASC:
      return 18;
    case Sort.DEVICE_TYPE_DESC:
      return 19;
    case Sort.TAG_TYPE_WELL_ASC:
      return 20;
    case Sort.TAG_TYPE_WELL_DESC:
      return 21;
    case Sort.TAG_TYPE_SITE_ASC:
      return 22;
    case Sort.TAG_TYPE_SITE_DESC:
      return 23;
    case Sort.TAG_TYPE_PRODUCTION_AREA_ASC:
      return 24;
    case Sort.TAG_TYPE_PRODUCTION_AREA_DESC:
      return 25;
    case Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * Information about a device.
 * A device is anything in the Hiber network that can send data to our systems.
 * They have a unique device number in our system, used to identify them.
 */
export interface Device {
  /** An 8-character hexadecimal string, formatted for human readability. System ignores spaces. */
  number: string;
  /** The organization that owns this device */
  organization: string;
  /** A descriptor given to the device, defaults to it's number. */
  name: string;
  location:
    | Location
    | undefined;
  /** The amount of time since the last message from this modem was received on the server. */
  inactivity?:
    | Duration
    | undefined;
  /** Health based on the modem alarm and some always-present alarms. */
  health:
    | HealthLevel
    | undefined;
  /** The current lifecycle the modem is in. */
  lifecycle: Modem_Lifecycle;
  /** additional information */
  peripherals: { [key: string]: string };
  /** Notes field that can be used to add additional information to a modem. */
  notes: string;
  /** Secure notes field that can be used to add additional information to a modem, with limited accessibility. */
  secureNotes?: string | undefined;
  tags: Tag[];
  /** Optional information about what other devices this devices is linked to. */
  link?:
    | Device_Links
    | undefined;
  /** Modem metadata, typically extracted from messages. */
  metadata:
    | { [key: string]: any }
    | undefined;
  /** The timezone configured for the modem. */
  timeZone?:
    | string
    | undefined;
  /** The transmission interval for this modem, if configured. */
  transmissionInterval?:
    | Duration
    | undefined;
  /** The expected transmission rate for this device. */
  expectedTransmissionRate?:
    | Value_Numeric_Rate
    | undefined;
  /** The DeviceType for this device. See DeviceType for more information. */
  type: string;
  /** The DeviceType for this device. See DeviceType for more information. */
  sensorBrand: string;
  /**
   * The numeric value types that this device produces.
   * The device may produce other values (like battery level), but these are the primary value types.
   */
  numericValueTypes: Value_Numeric_Type[];
  /**
   * Files for this tag.
   * Typically an image of the device installation. See the File.media_type for more information.
   */
  files: File[];
}

export interface Device_PeripheralsEntry {
  key: string;
  value: string;
}

/** Collection of data about the devices it is connected to. */
export interface Device_Links {
  /** Other identifiers for this devices. Could include data like its MAC-address or otherwise unique identifier. */
  identifiers: string[];
  /**
   * The gateway directly upstream from this device (in the direction of Mission Control).
   * This device sends its data directly to its gateway, which in turn relays it to Mission Control.
   */
  gateway?: string | undefined;
}

/**
 * Selection object for devices.
 * Filter devices by device number, tags, etc.
 */
export interface DeviceSelection {
  freeTextSearch?: string | undefined;
  modems?: Filter_Modems | undefined;
  identifiers?: Filter_ModemIdentifiers | undefined;
  healthLevel?: Filter_HealthLevels | undefined;
  lifecycles?: ModemFilter_Lifecycles | undefined;
  withGateways?: Filter_Modems | undefined;
  peripherals?: Filter_Properties | undefined;
  deviceTypes?: Filter_DeviceTypes | undefined;
  sensorBrands?: Filter_SensorBrands | undefined;
  filterByTags?: TagSelection | undefined;
  withLastMessageIn?: TimeRange | undefined;
}

export interface ModemFilter {
}

export interface ModemFilter_Lifecycles {
  include: Modem_Lifecycle[];
  exclude: Modem_Lifecycle[];
}

function createBaseDevice(): Device {
  return {
    number: "",
    organization: "",
    name: "",
    location: undefined,
    inactivity: undefined,
    health: undefined,
    lifecycle: Modem_Lifecycle.ACCEPTANCE_TESTING,
    peripherals: {},
    notes: "",
    secureNotes: undefined,
    tags: [],
    link: undefined,
    metadata: undefined,
    timeZone: undefined,
    transmissionInterval: undefined,
    expectedTransmissionRate: undefined,
    type: "",
    sensorBrand: "",
    numericValueTypes: [],
    files: [],
  };
}

export const Device = {
  encode(message: Device, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== "") {
      writer.uint32(10).string(message.number);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(34).fork()).ldelim();
    }
    if (message.inactivity !== undefined) {
      Duration.encode(message.inactivity, writer.uint32(42).fork()).ldelim();
    }
    if (message.health !== undefined) {
      HealthLevel.encode(message.health, writer.uint32(50).fork()).ldelim();
    }
    if (message.lifecycle !== Modem_Lifecycle.ACCEPTANCE_TESTING) {
      writer.uint32(56).int32(modem_LifecycleToNumber(message.lifecycle));
    }
    Object.entries(message.peripherals).forEach(([key, value]) => {
      Device_PeripheralsEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).ldelim();
    });
    if (message.notes !== "") {
      writer.uint32(74).string(message.notes);
    }
    if (message.secureNotes !== undefined) {
      writer.uint32(82).string(message.secureNotes);
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.link !== undefined) {
      Device_Links.encode(message.link, writer.uint32(98).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(106).fork()).ldelim();
    }
    if (message.timeZone !== undefined) {
      writer.uint32(114).string(message.timeZone);
    }
    if (message.transmissionInterval !== undefined) {
      Duration.encode(message.transmissionInterval, writer.uint32(122).fork()).ldelim();
    }
    if (message.expectedTransmissionRate !== undefined) {
      Value_Numeric_Rate.encode(message.expectedTransmissionRate, writer.uint32(130).fork()).ldelim();
    }
    if (message.type !== "") {
      writer.uint32(138).string(message.type);
    }
    if (message.sensorBrand !== "") {
      writer.uint32(146).string(message.sensorBrand);
    }
    writer.uint32(154).fork();
    for (const v of message.numericValueTypes) {
      writer.int32(value_Numeric_TypeToNumber(v));
    }
    writer.ldelim();
    for (const v of message.files) {
      File.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Device {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.inactivity = Duration.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.health = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.lifecycle = modem_LifecycleFromJSON(reader.int32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = Device_PeripheralsEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.peripherals[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.secureNotes = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.link = Device_Links.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.transmissionInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.expectedTransmissionRate = Value_Numeric_Rate.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.type = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.sensorBrand = reader.string();
          continue;
        case 19:
          if (tag === 152) {
            message.numericValueTypes.push(value_Numeric_TypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 154) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.numericValueTypes.push(value_Numeric_TypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.files.push(File.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Device>): Device {
    return Device.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Device>): Device {
    const message = createBaseDevice();
    message.number = object.number ?? "";
    message.organization = object.organization ?? "";
    message.name = object.name ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.inactivity = (object.inactivity !== undefined && object.inactivity !== null)
      ? Duration.fromPartial(object.inactivity)
      : undefined;
    message.health = (object.health !== undefined && object.health !== null)
      ? HealthLevel.fromPartial(object.health)
      : undefined;
    message.lifecycle = object.lifecycle ?? Modem_Lifecycle.ACCEPTANCE_TESTING;
    message.peripherals = Object.entries(object.peripherals ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.notes = object.notes ?? "";
    message.secureNotes = object.secureNotes ?? undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.link = (object.link !== undefined && object.link !== null)
      ? Device_Links.fromPartial(object.link)
      : undefined;
    message.metadata = object.metadata ?? undefined;
    message.timeZone = object.timeZone ?? undefined;
    message.transmissionInterval = (object.transmissionInterval !== undefined && object.transmissionInterval !== null)
      ? Duration.fromPartial(object.transmissionInterval)
      : undefined;
    message.expectedTransmissionRate =
      (object.expectedTransmissionRate !== undefined && object.expectedTransmissionRate !== null)
        ? Value_Numeric_Rate.fromPartial(object.expectedTransmissionRate)
        : undefined;
    message.type = object.type ?? "";
    message.sensorBrand = object.sensorBrand ?? "";
    message.numericValueTypes = object.numericValueTypes?.map((e) => e) || [];
    message.files = object.files?.map((e) => File.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDevice_PeripheralsEntry(): Device_PeripheralsEntry {
  return { key: "", value: "" };
}

export const Device_PeripheralsEntry = {
  encode(message: Device_PeripheralsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Device_PeripheralsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice_PeripheralsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Device_PeripheralsEntry>): Device_PeripheralsEntry {
    return Device_PeripheralsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Device_PeripheralsEntry>): Device_PeripheralsEntry {
    const message = createBaseDevice_PeripheralsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseDevice_Links(): Device_Links {
  return { identifiers: [], gateway: undefined };
}

export const Device_Links = {
  encode(message: Device_Links, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.identifiers) {
      writer.uint32(10).string(v!);
    }
    if (message.gateway !== undefined) {
      writer.uint32(18).string(message.gateway);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Device_Links {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice_Links();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifiers.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gateway = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Device_Links>): Device_Links {
    return Device_Links.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Device_Links>): Device_Links {
    const message = createBaseDevice_Links();
    message.identifiers = object.identifiers?.map((e) => e) || [];
    message.gateway = object.gateway ?? undefined;
    return message;
  },
};

function createBaseDeviceSelection(): DeviceSelection {
  return {
    freeTextSearch: undefined,
    modems: undefined,
    identifiers: undefined,
    healthLevel: undefined,
    lifecycles: undefined,
    withGateways: undefined,
    peripherals: undefined,
    deviceTypes: undefined,
    sensorBrands: undefined,
    filterByTags: undefined,
    withLastMessageIn: undefined,
  };
}

export const DeviceSelection = {
  encode(message: DeviceSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.freeTextSearch !== undefined) {
      writer.uint32(10).string(message.freeTextSearch);
    }
    if (message.modems !== undefined) {
      Filter_Modems.encode(message.modems, writer.uint32(18).fork()).ldelim();
    }
    if (message.identifiers !== undefined) {
      Filter_ModemIdentifiers.encode(message.identifiers, writer.uint32(26).fork()).ldelim();
    }
    if (message.healthLevel !== undefined) {
      Filter_HealthLevels.encode(message.healthLevel, writer.uint32(34).fork()).ldelim();
    }
    if (message.lifecycles !== undefined) {
      ModemFilter_Lifecycles.encode(message.lifecycles, writer.uint32(42).fork()).ldelim();
    }
    if (message.withGateways !== undefined) {
      Filter_Modems.encode(message.withGateways, writer.uint32(50).fork()).ldelim();
    }
    if (message.peripherals !== undefined) {
      Filter_Properties.encode(message.peripherals, writer.uint32(58).fork()).ldelim();
    }
    if (message.deviceTypes !== undefined) {
      Filter_DeviceTypes.encode(message.deviceTypes, writer.uint32(82).fork()).ldelim();
    }
    if (message.sensorBrands !== undefined) {
      Filter_SensorBrands.encode(message.sensorBrands, writer.uint32(90).fork()).ldelim();
    }
    if (message.filterByTags !== undefined) {
      TagSelection.encode(message.filterByTags, writer.uint32(66).fork()).ldelim();
    }
    if (message.withLastMessageIn !== undefined) {
      TimeRange.encode(message.withLastMessageIn, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.freeTextSearch = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modems = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.identifiers = Filter_ModemIdentifiers.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.healthLevel = Filter_HealthLevels.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lifecycles = ModemFilter_Lifecycles.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.withGateways = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.peripherals = Filter_Properties.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deviceTypes = Filter_DeviceTypes.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.sensorBrands = Filter_SensorBrands.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.filterByTags = TagSelection.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.withLastMessageIn = TimeRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeviceSelection>): DeviceSelection {
    return DeviceSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceSelection>): DeviceSelection {
    const message = createBaseDeviceSelection();
    message.freeTextSearch = object.freeTextSearch ?? undefined;
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? Filter_Modems.fromPartial(object.modems)
      : undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? Filter_ModemIdentifiers.fromPartial(object.identifiers)
      : undefined;
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? Filter_HealthLevels.fromPartial(object.healthLevel)
      : undefined;
    message.lifecycles = (object.lifecycles !== undefined && object.lifecycles !== null)
      ? ModemFilter_Lifecycles.fromPartial(object.lifecycles)
      : undefined;
    message.withGateways = (object.withGateways !== undefined && object.withGateways !== null)
      ? Filter_Modems.fromPartial(object.withGateways)
      : undefined;
    message.peripherals = (object.peripherals !== undefined && object.peripherals !== null)
      ? Filter_Properties.fromPartial(object.peripherals)
      : undefined;
    message.deviceTypes = (object.deviceTypes !== undefined && object.deviceTypes !== null)
      ? Filter_DeviceTypes.fromPartial(object.deviceTypes)
      : undefined;
    message.sensorBrands = (object.sensorBrands !== undefined && object.sensorBrands !== null)
      ? Filter_SensorBrands.fromPartial(object.sensorBrands)
      : undefined;
    message.filterByTags = (object.filterByTags !== undefined && object.filterByTags !== null)
      ? TagSelection.fromPartial(object.filterByTags)
      : undefined;
    message.withLastMessageIn = (object.withLastMessageIn !== undefined && object.withLastMessageIn !== null)
      ? TimeRange.fromPartial(object.withLastMessageIn)
      : undefined;
    return message;
  },
};

function createBaseModemFilter(): ModemFilter {
  return {};
}

export const ModemFilter = {
  encode(_: ModemFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemFilter>): ModemFilter {
    return ModemFilter.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ModemFilter>): ModemFilter {
    const message = createBaseModemFilter();
    return message;
  },
};

function createBaseModemFilter_Lifecycles(): ModemFilter_Lifecycles {
  return { include: [], exclude: [] };
}

export const ModemFilter_Lifecycles = {
  encode(message: ModemFilter_Lifecycles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.include) {
      writer.int32(modem_LifecycleToNumber(v));
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.exclude) {
      writer.int32(modem_LifecycleToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemFilter_Lifecycles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemFilter_Lifecycles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.include.push(modem_LifecycleFromJSON(reader.int32()));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(modem_LifecycleFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.exclude.push(modem_LifecycleFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(modem_LifecycleFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemFilter_Lifecycles>): ModemFilter_Lifecycles {
    return ModemFilter_Lifecycles.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemFilter_Lifecycles>): ModemFilter_Lifecycles {
    const message = createBaseModemFilter_Lifecycles();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
