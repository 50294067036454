import { routes } from '@/router/routes';
import { useUserStore } from '@/store/user';
import { State } from '@/utils';
import { getAuth, multiFactor, User } from '@firebase/auth';
import { NavigationGuardNext, NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';
import { useOrganizationStore } from '@/store/organization';
import { useLocalStorage } from '@vueuse/core';
import { Rule } from '@/acl/rules';
import { useAcl } from 'vue-simple-acl';

const { can } = useAcl();

type LoggedInHookType = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  currentUser: User,
) => Promise<void>;

const allowedPaths = [
  routes.emailVerification.fullPath,
  routes.mfa.fullPath,
  routes.mfa.children[0].fullPath,
  routes.termsAndConditions.fullPath,
  routes.organizationsRequest.fullPath,
  routes.organizationsInvites.fullPath,
  routes.organizationsJoin.fullPath,
  routes.organizationsCreate.fullPath,
];

const loggedIn: LoggedInHookType = async (to, from, next, currentUser) => {
  // user is authenticated, fetch user details from hiber backend
  const userStore = useUserStore();
  const skipMfa = useLocalStorage(`skip-enroll-mfa-${currentUser.uid}`, false);

  userStore.isAuthenticated = true;

  if (allowedPaths.includes(to.fullPath)) {
    next();
  } else if (!to.meta.requiresAuth) {
    next({ path: routes.dashboard.path });
  } else if (!currentUser.emailVerified && !userStore.useLocalTokenAuth) {
    // user doesn't have the email verified
    next({ path: routes.emailVerification.fullPath });
  } else if (
    !userStore.useLocalTokenAuth &&
    currentUser.providerData?.some((provider) => provider.providerId === 'password') &&
    multiFactor(currentUser)?.enrolledFactors?.length === 0 &&
    (from.path === routes.login.path || from.path === routes.createUser.path) &&
    !skipMfa.value
  ) {
    next({ path: routes.mfa.fullPath });
  } else {
    // user is authenticated, fetch user details from hiber backend
    const organizationStore = useOrganizationStore();

    try {
      userStore.$patch({ state: State.LOADING });
      const user = await userStore.fetchCurrentUser();

      let redirectRoute;
      const defaultScreenRedirect = userStore.missionControlSettings.defaultScreen ?? '/dashboard';

      // check if user previously accepted terms and conditions
      if (!user.acceptedTac) {
        redirectRoute = { path: routes.termsAndConditions.fullPath };
      }
      // if user doesn't have any organization assigned, need to redirect to Organization request/invite pages
      else if (!userStore.userHasOrganization) {
        redirectRoute = { path: routes.organizationsRequest.fullPath };
      }
      // if user attempted to access a page without being authenticated, redirect to original destination
      else if (from.path === routes.login.path && from.redirectedFrom && from.redirectedFrom.path !== to.path) {
        if (!(from.redirectedFrom.path === '/' && to.path === '/dashboard')) {
          redirectRoute = from.redirectedFrom;
        }
      } else if (
        to.redirectedFrom?.path === routes.dashboard.path &&
        ['/dashboard', '/devices'].includes(to.path) &&
        to.path !== defaultScreenRedirect &&
        !redirectRoute
      ) {
        redirectRoute = defaultScreenRedirect;
      }

      if (redirectRoute) {
        next(redirectRoute);
      } else {
        next();
      }
    } catch (error) {
      // if Status.PERMISSION_DENIED = 7
      if (error.code === 7) {
        organizationStore.organizationImpersonation = '';
        organizationStore.organization = null;
        next('/');
      } else {
        // if we can't get "CurrentUser" we might just well signout the user
        await getAuth().signOut();
        next('/login');
      }
    } finally {
      userStore.$patch({ state: State.READY });
    }
  }
};

const notLoggedIn: NavigationGuardWithThis<void> = (to, from, next) => {
  // user is not authenticated, and it requires to be
  // should be redirected to /login, otherwise continue
  if (to.meta.requiresAuth) {
    const query =
      to.path !== routes.dashboard.path
        ? { redirect: to.path, organization: to.query.organization }
        : { organization: to.query.organization };

    const canUseToken = can(Rule.TOKEN_AUTH);
    const canUsePassword = can(Rule.PASSWORD_AUTH);

    if (canUsePassword) {
      next({ path: routes.login.path, query });
    } else if (canUseToken) {
      next({ path: routes.tokenLogin.path, query });
    } else {
      next({ path: routes.error.path, replace: true });
    }
  } else {
    next();
  }
};

const authGuard: NavigationGuardWithThis<void> = async (to, from, next) => {
  const userStore = useUserStore();
  let currentUser;
  if (userStore.useLocalTokenAuth) {
    try {
      currentUser = await userStore.fetchCurrentUser();
    } catch (e) {
      await userStore.signout();
    }
  } else {
    currentUser = getAuth().currentUser;
  }

  if (!currentUser) {
    notLoggedIn(to, from, next);
  } else {
    await loggedIn(to, from, next, currentUser);
  }
};

export default authGuard;
